<template>
  <div class="nonkey-check-record list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="5">
          <el-form-item label="点检时间" prop="tpmTime">
            <el-date-picker 
              v-model="formData.tpmTime" 
              value-format="YYYY-MM-DD" 
              placeholder="请选择点检时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
         <el-col :span="5">
          <el-form-item label="工具名称" prop="keywords">
            <el-input v-model="formData.keywords" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="点检状态" prop="tmpResultStatus">
            <el-select v-model="formData.tmpResultStatus" placeholder="请选择">
              <el-option
                v-for="item in tpmStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button class="active" @click="handleExport"><img src="@/assets/imgs/export.png" />导出</el-button>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column prop="code" label="工具编号"></el-table-column>
        <el-table-column prop="name" label="工具名称"></el-table-column>
        <el-table-column prop="workStationName" label="所属工段"></el-table-column>
        <el-table-column prop="classGroupName" label="所属班组"></el-table-column>
        <el-table-column label="所属工位/班次">
          <template #default="{row}">{{row.empStationName}}/{{row.classShift}}</template>
        </el-table-column>
        <el-table-column prop="tpmResult" label="点检结果" :formatter="formatterTpmResult">
          <template #default="{row, column}">
            <span :class="{'warning': row.tpmResult != 'OK'}">{{formatterTpmResult(row, column, row.tpmResult)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="operator" label="点检操作员"></el-table-column>
        <el-table-column prop="tpmTime" label="点检时间"></el-table-column>
        <el-table-column label="操作" width="80" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleDetail(row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情弹框 -->
    <el-dialog v-model="detailDialogVisible" width="1000px" @close="detailDialogVisible = false">
      <template #title>
        <h1>详细信息</h1>
        <p>details</p>
      </template>
      <el-table :data="detailTableData">
        <el-table-column prop="tpmItemName" label="点检项"></el-table-column>
        <el-table-column prop="itemPoints" label="检查点"></el-table-column>
        <el-table-column prop="tpmMethod" label="点检方法"></el-table-column>
        <el-table-column prop="tpmStatus" label="状态"></el-table-column>
        <el-table-column prop="tpmRate" label="点检频次"></el-table-column>
        <el-table-column prop="tpmOptionCode" label="点检结果" :formatter="formatterTpmResult"></el-table-column>
      </el-table>        
      <template #footer>
        <el-button @click="detailDialogVisible = false">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { record, common } from '@/api'

export default {
  name: 'NonkeyCheckRecord',
  data() {
    return {
      formData: { // 查询表单
        tpmTime: '',
        keywords: '',
        tmpResultStatus: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      tpmStatusList: [], // 点检状态列表

      detailDialogVisible: false, // 详情弹框visible
      detailTableData: [], // 详情
    }
  },
  created() {
    this.getDict()
    this.loadTableData()
  },
  methods: {
    // 获取字典数据
    async getDict() {
      let types = 'TPM_STATUS'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.tpmStatusList = data.TPM_STATUS || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await record.getNonkeyRecordList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 点击详情
    async handleDetail({id}) {
      const {code, msg, data} = await record.getNonkeyRecordDetail(id)
      if(code === 0) {
        let options = data && data.options
        this.detailTableData = options || []
        this.detailDialogVisible = true
      } else {
        this.$message.error(msg)
      }
    },
    // 导出
    async handleExport() {
      const loading = this.$loading({
        lock: true,
        text: '导出的数据较大，请等待...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      try {
        await common.downloadFile('/non-asset/tpm/export', null, this.formData)
      } catch (e) {
        console.log(e);
      } finally {
        loading.close();
      }
    },
    // 格式化点检结果
    formatterTpmResult(row, column, cellValue) {
      if(cellValue == 'ABNORMAL') {
        return '异常'
      }
      if(cellValue == 'OK') {
        return '正常'
      }
      return cellValue
    },
  }
}
</script>

<style lang="scss" scoped>
.nonkey-check-record {
}
</style>